<script setup lang="ts">
import { ArrowLeftOutlined } from '@ant-design/icons-vue'

defineProps({ title: { type: String, default: '标题' }, needBack: { type: Boolean, default: false } })
const emit = defineEmits(['onBack'])
function back() {
  emit('onBack')
}
</script>

<template>
  <div flex justify-between items-center h-48px w-full px-20px font-semibold text-base bg-white style="color: rgba(0, 0, 0, .85);border-bottom: 1px solid #e6e6e6;">
    <div flex items-center>
      <ArrowLeftOutlined v-if="needBack" cursor-pointer style="color: var(--pro-ant-blue);" @click="back" />
      <span :class="{ 'mx-10px': needBack }">{{ title }}</span>
      <slot name="extra" />
    </div>
    <div flex items-center>
      <slot name="right" />
    </div>
  </div>
</template>
